<template>
  <v-dialog :value="true" max-width="600" scrollable persistent>
    <v-card :loading="loading">
      <v-toolbar color="green" dark
        ><v-toolbar-title>{{
          isNew ? "Feedback erfassen" : "Feedback bearbeiten"
        }}</v-toolbar-title></v-toolbar
      >

      <v-list two-line v-if="item">
        <v-list-item>
          <v-list-item-avatar>
            <v-icon> mdi-calendar </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ item.period.description }}</v-list-item-title>
            <v-list-item-subtitle>{{
              formatDatespan(item.period.startDate, item.period.endDate)
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <PersonItemBasic :value="item.person" v-if="item.person" />
        <v-list-item>
          <v-list-item-avatar>
            <v-icon> mdi-school </v-icon>
          </v-list-item-avatar>
          <v-autocomplete
            v-model="item.course"
            :disabled="courses.length < 1"
            :items="courses"
            label="Kurs"
            clearable
            return-object
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.title }} </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    formatDatespan(data.item.startDate, data.item.endDate)
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </template>
            <template v-slot:selection="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.title }} </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    formatDatespan(data.item.startDate, data.item.endDate)
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon> mdi-tools </v-icon>
          </v-list-item-avatar>
          <LookupValueInput
            v-model="item.method"
            :items="methods"
            label="Methode"
        /></v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon> mdi-calendar </v-icon>
          </v-list-item-avatar>
          <DateInput v-model="item.date" label="Datum der Durchführung"
        /></v-list-item>
      </v-list>
      <v-divider />
      <v-card-actions
        ><v-btn text exact :to="{ name: 'QualityEdit' }">abbrechen</v-btn>
        <v-spacer />

        <v-btn
          text
          color="danger"
          @click="trash"
          :loading="deleting"
          :disabled="isNew"
          >löschen</v-btn
        >
        <v-btn
          text
          color="primary"
          @click="save"
          :loading="saving"
          :disabled="!saveable"
          >speichern</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { defineComponent } from "vue";
import { formatDatespan } from "common/utils/date.js";
import DateInput from "common/components/DateInput.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonItemBasic from "common/components/PersonItemBasic.vue";
export default defineComponent({
  components: { DateInput, LookupValueInput, PersonItemBasic },
  props: ["id"],
  data() {
    return {
      courses: [],
      deleting: false,
      item: null,
      loading: false,
      methods: [],
      period: null,
      saving: false,
    };
  },
  computed: {
    isNew() {
      return this.id == 0;
    },
    saveable() {
      return (
        this.item && this.item.course && this.item.method && this.item.date
      );
    },
  },
  methods: {
    formatDatespan,
    async fetchData() {
      this.loading = true;
      const methods_all = await this.apiList({
        resource: "quality/method",
      });
      this.methods = methods_all
        .filter((el) => el.available)
        .sort((a, b) => a.code.localeCompare(b.code));
      this.period = await this.apiList({
        resource: "quality/period",
        query: "current",
      });
      this.courses = await this.apiList({
        resource: "course/course",
        query: `person=${this.$_profilePerson.id}&startDate=${this.period.startDate}&endDate=${this.period.endDate}`,
      });
      if (this.isNew) {
        this.item = {
          person: this.$_profilePerson,
          period: this.period,
          method: null,
          course: null,
          date: null,
        };
      } else {
        this.item = await this.apiGet({
          resource: "quality/feedback",
          id: this.id,
        });
      }

      this.loading = false;
    },
    async save() {
      this.saving = true;
      if (this.isNew) {
        await this.apiPost({
          resource: "quality/feedback",
          data: this.item,
        });
      } else {
        await this.apiPut({
          resource: "quality/feedback",
          data: this.item,
        });
      }

      this.saving = false;
      this.$root.showSuccess("Erfolgreich gespeichert");
      this.$router.push({ name: "QualityEdit" });
    },
    async trash() {
      if (
        await this.$root.confirm({
          message: `Soll dein Feedback des Kurses «${this.item.course.title}» entfernt werden?`,
          color: "error",
          icon: "mdi-trash-can",
        })
      ) {
        this.deleting = true;

        await this.apiDelete({
          resource: "quality/feedback",
          id: this.item.id,
        });

        this.deleting = false;
        this.$root.showSuccess("Erfolgreich gelöscht");
        this.$router.push({ name: "QualityEdit" });
      }
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
